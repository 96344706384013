/* eslint-disable no-console */
import { updateOneUserProfile } from '../API/usersAPI';
import {
  getFirebaseToken,
  requestNotificationPermission,
  // messaging,
  // onMessaging,
} from '../config/firebase';

const updateFirebaseToken = async (fcmToken, userStoreToken) => {
  await updateOneUserProfile(userStoreToken, {
    firebaseToken: fcmToken,
  }).catch((err) => {
    console.log(err);
  });
};

const isNullOrUndefinedOrEmpty = (str) => !str || str.trim().length === 0;

const shouldUpdateToken = async (newToken, user) => {
  if (!user || !newToken) {
    return false;
  }

  // Case 1: token is null
  if (isNullOrUndefinedOrEmpty(user?.firebaseToken)) {
    return true;
  }

  // Case 2: New token match with existing token
  if (newToken === user?.firebaseToken) {
    return false;
  }

  // Case 3: New token doesn't match with existing token
  if (newToken !== user?.firebaseToken) {
    return true;
  }

  return false;
};

const setupFcmToken = async (userStoreSession, userStoreToken) => {
  if (!userStoreSession || !userStoreToken) {
    return true;
  }

  const permissionGranted = await requestNotificationPermission()
    .then((permission) => permission)
    .catch((error) => {
      console.log(error);
    });
  const newFcmToken = await getFirebaseToken((token) => token).catch(
    (error) => {
      console.log(error);
    }
  );

  if (permissionGranted) {
    if (newFcmToken) {
      const shouldUpdate = await shouldUpdateToken(
        newFcmToken,
        userStoreSession
      ).catch((error) => {
        console.log(error);
      });

      // Check if update is needed
      if (!shouldUpdate) {
        return true;
      }

      await updateFirebaseToken(newFcmToken, userStoreToken).catch((error) => {
        console.log(error);
      });

      // if (messaging) {
      //   onMessaging(messaging, (payload) => {
      //     toast.info(
      //       `${payload.notification.title}: ${payload.notification.body}`
      //     );
      //   });
      // }
    }
  }
  return true;
};

export default setupFcmToken;
