/* eslint-disable no-console */
import { toast } from 'react-toastify';
import { fetchAllOpenCalls } from '../reducers/openCalls';

const audioFile = new Audio('/audio/intelisys_notification_audio.wav');

const handleNotification = (
  notification,
  handleNotificationClick,
  userToken,
  userSessionId,
  dispatch
) => {
  toast(`${notification?.message?.header}: ${notification?.message?.content}`, {
    position: 'top-center',
    autoClose: 10000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'light',
    onClick: () => handleNotificationClick(),
  });

  // Play the audio file
  audioFile.play().catch((error) => {
    console.error('Error playing the audio file:', error);
  });

  // Dispatch the action to fetch the latest calls
  dispatch(fetchAllOpenCalls({ userToken, userSessionId }));
};

export default handleNotification;
