import axios from 'axios';

const findAllSubscribedCalls = async (token, sessionId) =>
  axios.get(`${process.env.REACT_APP_BASE_URL}/calls/subscribed`, {
    headers: {
      Authorization: token,
      usersessionid: sessionId,
    },
  });

export default findAllSubscribedCalls;
