import axios from 'axios';

export const findOneUserProfile = (token) =>
  axios.post(
    `${process.env.REACT_APP_BASE_URL}/users/1/myprofile`,
    {},
    {
      headers: {
        Authorization: token,
      },
    }
  );

export const findAllOrders = (token) =>
  axios.post(
    `${process.env.REACT_APP_BASE_URL}/users/1/orders`,
    {},
    {
      headers: {
        Authorization: token,
      },
    }
  );

export const findAllSubscriptions = (token) =>
  axios.post(
    `${process.env.REACT_APP_BASE_URL}/users/1/subscriptions`,
    {},
    {
      headers: {
        Authorization: token,
      },
    }
  );

export const updateOneUserProfile = (token, body) =>
  axios.patch(`${process.env.REACT_APP_BASE_URL}/users/1/profile`, body, {
    headers: {
      Authorization: token,
    },
  });

export const findUserAllWatchLists = (token) =>
  axios.post(
    `${process.env.REACT_APP_BASE_URL}/users/1/watchlists`,
    {},
    {
      headers: {
        Authorization: token,
      },
    }
  );

export const findAllUserPayouts = async (token, body) =>
  axios.post(`${process.env.REACT_APP_BASE_URL}/users/1/payouts`, body, {
    headers: {
      Authorization: token,
    },
  });

export const generatePayoutRequest = async (token, body) =>
  axios.post(
    `${process.env.REACT_APP_BASE_URL}/users/1/payouts/generate`,
    body,
    {
      headers: {
        Authorization: token,
      },
    }
  );

export const verifyPayoutRequest = async (token, body) =>
  axios.post(`${process.env.REACT_APP_BASE_URL}/users/1/payouts/verify`, body, {
    headers: {
      Authorization: token,
    },
  });

export const findAllReferralsAnalytics = async (token) =>
  axios.post(
    `${process.env.REACT_APP_BASE_URL}/users/1/referrals/analytics`,
    {},
    {
      headers: {
        Authorization: token,
      },
    }
  );

export const findAllReferrals = async (token) =>
  axios.post(
    `${process.env.REACT_APP_BASE_URL}/users/1/referrals`,
    {},
    {
      headers: {
        Authorization: token,
      },
    }
  );
