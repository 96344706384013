/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import findAllSubscribedCalls from '../API/callsAPI';
import { errorHandler } from '../utils/errorHandler';
import Logout from '../utils/logout';

export const initialState = {
  callList: [],
  selectedPlan: '',
  segmentFilter: '',
  searchText: '',
  searchedCallsList: [],
  isLoading: true,
  error: null,
};

// Create async thunk for API call
export const fetchAllOpenCalls = createAsyncThunk(
  'calls/fetchAllOpenCalls',
  async ({ userToken, userSessionId }, { rejectWithValue }) => {
    try {
      const response = await findAllSubscribedCalls(userToken, userSessionId);
      return response.data.calls;
    } catch (error) {
      await errorHandler(error);
      return rejectWithValue(error);
    }
  }
);

const OpenCallsReducer = createSlice({
  name: 'LIVE-OPEN-CALLS',
  initialState,
  reducers: {
    setSelectedPlan: (state, action) => {
      state.selectedPlan = action.payload;
    },
    setSegmentFilter: (state, action) => {
      state.segmentFilter = action.payload;
    },
    setSearchText: (state, action) => {
      state.searchText = action.payload;
    },
    setSearchedCallsList: (state, action) => {
      state.searchedCallsList = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllOpenCalls.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchAllOpenCalls.fulfilled, (state, action) => {
        state.isLoading = false;
        state.callList = action.payload;
        state.error = null;
      })
      .addCase(fetchAllOpenCalls.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
        if (action.payload?.response?.status === 401) {
          Logout();
        }
      });
  },
});

export const {
  setSelectedPlan,
  setSegmentFilter,
  setSearchText,
  setSearchedCallsList,
} = OpenCallsReducer.actions;

export default OpenCallsReducer.reducer;
